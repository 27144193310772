import {AfterViewInit, Component, DestroyRef, inject, Input, output, Output, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {BehaviorSubject, catchError, debounceTime, map, startWith} from 'rxjs';
import {AreaCategory, Area, PlacementStatus, PoVo, NewComer} from '../../../gql/graphql';
import {FormControl, FormGroup} from '@angular/forms';
import {
    hasNoEmptyAttributes,
    notNull,
    notNullOrUndefined,
    throwExpression
} from '../../shared/utils';
import {Router} from '@angular/router';
import {gql} from '../../../gql';
import {ErrorService} from '../../shared/services/error.service';
import { AdditionalRouteEvents } from 'src/app/app-route-reuse-strategy';
import { VTableComponent } from '@vasio-nl/valow';
import { models } from 'src/app/shared/models';
import { FormHelperService } from 'src/app/shared/services/form-helper.service';
import { NewcomerStatusColumnComponent } from './newcomer-status-column/newcomer-status-column.component';
import { NewcomerNameColumnComponent } from './newcomer-name-column/newcomer-name-column.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NewcomerAgeColumnComponent } from './newcomer-age-column/newcomer-age-column.component';

const SHOULD_HAVE_BEEN_PLACED_QUERY = gql(/* GraphQL */`
    query OverviewComponent_shouldHaveBeenPlaced {
        newcomers(shouldHaveBeenPlaced: true) {
            totalCount
        }
    }
`);

const AREAS = gql(/* GraphQL */`
    query OverviewComponent_areas {
        areas {
            id
            name
            category
        }
    }
`);

type severityColors = 'success' | 'info' | 'warning' | 'danger';

export const PLACEMENT_STATUS_OPTIONS: { [key in keyof typeof PlacementStatus]: { value: (typeof PlacementStatus & { all: null })[key] | null, name: string, color: severityColors | undefined } } = {
    NEW: {name: 'Nieuw', value: PlacementStatus.NEW, color: 'info'},
    SIGNUP_PO: {name: 'Aanmelden PO', value: PlacementStatus.SIGNUP_PO, color: 'warning'},
    DOUBLE_REGISTRATION: {name: 'Dubbel', value: PlacementStatus.DOUBLE_REGISTRATION, color: 'warning'},
    PLACED: {name: 'Geplaatst Nieuwkomersgroep (regulier)', value: PlacementStatus.PLACED, color: 'success'},
    PLACED_ARRIVAL_LOCATION: {name: 'Geplaatst Nieuwkomersgroep (noodplan)', value: PlacementStatus.PLACED_ARRIVAL_LOCATION, color: 'success'},
    PLACED_TEMPORARY_LOCATION: {name: 'Geplaatst Nieuwkomersgroep (TOV)', value: PlacementStatus.PLACED_TEMPORARY_LOCATION, color: 'success'},
    COMPULSORY_EDUCATION: {name: 'Leerplicht', value: PlacementStatus.COMPULSORY_EDUCATION, color: 'warning'},
    YET_TO_ARRIVE: {name: 'Nog niet in Den haag', value: PlacementStatus.YET_TO_ARRIVE, color: 'warning'},
    RESERVED: {name: 'Reservering Nieuwkomersgroep (regulier)', value: PlacementStatus.RESERVED, color: undefined},
    LEFT: {name: 'Vertrokken uit gemeente', value: PlacementStatus.LEFT, color: 'warning'},
    WAITING_LIST: {name: 'Op wachtlijst', value: PlacementStatus.WAITING_LIST, color: 'info'},
    WAITING_LIST_ARRIVAL_LOCATION: {name: 'Reservering Nieuwkomersgroep (noodplan)', value: PlacementStatus.WAITING_LIST_ARRIVAL_LOCATION, color: undefined},
    WAITING_LIST_TEMPORARY_LOCATION: {name: 'Reservering Nieuwkomersgroep (TOV)', value: PlacementStatus.WAITING_LIST_TEMPORARY_LOCATION, color: undefined},
    WAITING_LIST_CARE: {name: 'Op wachtlijst (zorg)', value: PlacementStatus.WAITING_LIST_CARE, color: 'warning'},
    IMPROVEMENT_NEEDED: {name: 'Plaatsverbetering', value: PlacementStatus.IMPROVEMENT_NEEDED, color: 'warning'},
    OUTSIDE_THE_HAGUE: {name: 'Buiten Den Haag', value: PlacementStatus.OUTSIDE_THE_HAGUE, color: undefined},
    TODDLER: {name: 'Kleuter', value: PlacementStatus.TODDLER, color: undefined},
    TODDLER_WAITING_LIST: {name: 'Wachtlijst Kleuter', value: PlacementStatus.TODDLER_WAITING_LIST, color: 'warning'},
    REFUSSAL: {name: "Plek geweigerd", value: PlacementStatus.REFUSSAL, color: 'warning'},
    SIGNUP_VO: {name: "Aanmelden VO", value: PlacementStatus.SIGNUP_VO, color: 'warning'},
    NO_CONTACT: {name: "Geen contact", value: PlacementStatus.NO_CONTACT, color: 'warning'},
    ALREADY_FOUND_A_SCHOOL: {name: "Reeds een school gevonden", value: PlacementStatus.ALREADY_FOUND_A_SCHOOL, color: 'warning'},
    EXEMPT_FROM_EDUCATION: {name: "Vrijgesteld van onderwijs", value: PlacementStatus.EXEMPT_FROM_EDUCATION, color: 'warning'},
};

const SPEAKS_UKRAINIAN_OPTIONS: {[key in 'yes' | 'no' | 'either']: {name: string, value: boolean | null}} = {
    either: {name: 'Alle talen', value: null},
    yes: {name: 'Oekraïens', value: true},
    no: {name: 'Geen Oekraïens', value: false}
  };

  const PO_VO_OPTIONS: {[key in PoVo.PO | PoVo.VO | 'either']: {name: string, value: key | null}} = {
    either: {name: 'PO/VO', value: null},
    [PoVo.PO]: {name: 'PO', value: PoVo.PO},
    [PoVo.VO]: {name: 'VO', value: PoVo.VO}
  };

  @Component({
    selector: 'app-newcomer-overview',
    templateUrl: './newcomer-overview.component.html',
    styleUrls: ['./newcomer-overview.component.scss'],
  })
  export class NewcomerOverviewComponent
  implements AfterViewInit, AdditionalRouteEvents
  {
  destroyRef = inject(DestroyRef);

  @ViewChild('table', { static: true }) table: VTableComponent<
    any,
    any,
    any
  > | null = null;
  models = models;
  tableFields = this.formHelperService.getTableFields('NewComer', [
    {
      field: 'id',
      flex: '0 1 80px',
    },
    {
      field: 'placementStatus',
      flex: '0 1 160px',
      customComponent: NewcomerStatusColumnComponent,
    },
    {
      field: 'fullName',
      title: 'Nieuwkomer',
      customComponent: NewcomerNameColumnComponent,
      flex: '1 1 200px',
    },
    {
      field: 'age',
      flex: '0 1 100px',
      customComponent: NewcomerAgeColumnComponent
    },
    {
      field: 'arrivalDate',
      flex: '0 1 150px',
    },
    {
      field: 'registrationDate',
      flex: '0 1 150px',
    },
  ]);

  additionalFields = this.formHelperService.getTableFields('NewComer', [
    'gender',
    'latitude',
    'longitude',
  ]);


  @Input() set location(value: { latitude: number; longitude: number }) {
    const { latitude, longitude } = value;
    this.searchForm.patchValue({ rangeFilter: { latitude, longitude } });
    this.rangeFilterActive = true;
    this.searchForm.controls.rangeFilter.controls.metersRange.setValue(3000);
  }

  @Input() oldFirst = false;
  placementOptions = PLACEMENT_STATUS_OPTIONS;
  placementOptionsArray = Object.values(PLACEMENT_STATUS_OPTIONS);
  ukrainianOptionsArray = Object.values(SPEAKS_UKRAINIAN_OPTIONS);
  PoVoOptionsArray = Object.values(PO_VO_OPTIONS);
  showShouldHaveBeenPlacedWarning = false;

  distanceOptionsArray: { name: string; value: number }[] = [
    { name: '15KM', value: 15000 },
    { name: '10KM', value: 10000 },
    { name: '5KM', value: 5000 },
    { name: '3KM', value: 3000 },
    { name: '2KM', value: 2000 },
    { name: '1KM', value: 1000 },
    { name: '500M', value: 500 },
  ];

  searchForm = new FormGroup({
    search: new FormControl(null),
    placementStatus: new FormControl([]),
    rangeFilter: new FormGroup({
      metersRange: new FormControl(),
      latitude: new FormControl(),
      longitude: new FormControl(),
    }),
    areaId: new FormControl(null),
    speaksUkrainian: new FormControl(null),
    shouldHaveBeenPlaced: new FormControl(false),
  });
  @Output() filters = new BehaviorSubject<{}>({});

  recordCount: number = 0;
  loadingRecordCount = false;

  rangeFilterActive = false;
  areas: Area[] = [];


  constructor(
    private apollo: Apollo,
    public formHelperService: FormHelperService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  ngAfterViewInit(): void {
    if(!this.table) {
      throwExpression('Table did not initialize');
    }

    this.searchForm.valueChanges
    .pipe(
      debounceTime(500),
      startWith(this.searchForm.value),
      map((filters) => {
        let rangeFilter = null;

        if (notNullOrUndefined(filters.rangeFilter)) {
          if (
            hasNoEmptyAttributes(filters.rangeFilter, [
              'latitude',
              'longitude',
            ])
          ) {
            rangeFilter = filters.rangeFilter;
          }
        }

        const { placementStatus, ...otherFilters } = filters;

        return {
          ...otherFilters,
          placementStatus__in: placementStatus,
          rangeFilter: rangeFilter,
          oldFirst: this.oldFirst
        };
      }),
      takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value) => {
        this.filters.next(value);
      });

    this.apollo
      .query({
        query: SHOULD_HAVE_BEEN_PLACED_QUERY,
      })
      .pipe(
        catchError(() =>
          this.errorService.HandleGraphQLError(
            'Ophalen van hoeveelheid nieuwkomers die geplaatst hadden moeten zijn'
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((output) => {
        if (output.data.newcomers.totalCount > 0) {
          this.showShouldHaveBeenPlacedWarning = true;
        }
      });

    this.apollo
      .watchQuery({
        query: AREAS,
      })
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((output) => {
        this.areas = output.data.areas;
      });
  }

  navigate(newcomer: NewComer): void {
    this.router.navigate(['/', 'nieuwkomer', newcomer.id]).then();
  }

  willEnter(): void {
    setTimeout(() => {
      notNull(this.table).scrollTop();
    });
  }

  categoryToDisplay(category: AreaCategory) {
    switch (category) {
      case AreaCategory.WIJK:
        return 'Wijk';
      case AreaCategory.BUURT:
        return 'Buurt';
      case AreaCategory.STADSDEEL:
        return 'Stadsdeel';
    }
  }
}
