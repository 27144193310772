@let name = value();
@let newcomerData = rowData();
{{ name }}
<ng-container [ngSwitch]="newcomerData.gender">
  <span *ngSwitchCase="'MALE'" title="Jongen">♂</span>
  <span *ngSwitchCase="'FEMALE'" title="Meisje">♀</span>
</ng-container>
<ng-container *ngIf="newcomerData.longitude === null || newcomerData.latitude === null">
  <span class="p-tag p-tag-warning address-warning-tag">onbekend adres</span>
</ng-container>
