import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {catchError, EMPTY, Observable, of} from 'rxjs';
import {OAuthModuleConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {AuthenticationService} from './shared/services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<never>, next: HttpHandler) {
    if (request.url.indexOf(environment.oauth_endpoint) === 0 || request.url.indexOf(environment.api_endpoint) === 0) {
      const csrftoken = this.getCookie('scholenwijzer_csrf');
      request = request.clone({
        withCredentials: true,
        headers: csrftoken ? request.headers.set('X-CSRFToken', csrftoken) : request.headers
      });

      return next.handle(request).pipe(
        catchError((error: { status: number }, caught) => {
          if (error.status === 401) {
            this.redirectToLogin();
            return EMPTY;
          }

          throw error;
        })
      ) as Observable<never>;
    }

    return next.handle(request);
  }

  redirectToLogin() {
    window.location.href = environment.oauth_endpoint + '?next=' + encodeURI(location.href);
  }
  private getCookie(name: string) {
      let cookieValue: string | null = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    }
}
