import {AfterViewInit, Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {gql} from '../../../gql';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, catchError, combineLatest, delay, EMPTY, filter, map, Observable, share, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {hasNoEmptyAttributes, notNullOrUndefined, QueryResult, throwExpression} from '../../shared/utils';
import {NewcomerOverviewComponent} from '../newcomer-overview/newcomer-overview.component';
import {ErrorService} from '../../shared/services/error.service';
import {environment} from '../../../environments/environment';
import { LocationComponent_NewcomersQuery, LocationComponent_NewcomersQueryVariables } from 'src/gql/graphql';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const LOCATION_QUERY = gql(/* GraphQL */`
  query LocationComponent_information($id: ID!) {
    location(id: $id) {
      id
      name
      street
      houseNumber
      houseNumberAddition
      zipcode
      place
      latitude
      longitude
      newcomerToken
      totalCapacity
      remainingCapacity
    }
  }
`);

const NEWCOMERS_QUERY = gql(/* GraphQL */`
  query LocationComponent_newcomers(
      $search: String,
      $rangeFilter: RangeFilter,
      $areaId: ID,
      $placementStatus__in: [PlacementStatus!],
      $shouldHaveBeenPlaced: Boolean,
      $speaksUkrainian: Boolean
  ) {
      newcomers(
          search: $search,
          rangeFilter: $rangeFilter,
          areaId: $areaId,
          placementStatus__in: $placementStatus__in,
          shouldHaveBeenPlaced: $shouldHaveBeenPlaced,
          speaksUkrainian: $speaksUkrainian,
          oldFirst: true,
      ) {
          edges {
              node {
                  __typename
                  id
                  fullName
                  latitude
                  longitude
              }
          }
      }
  }
`);

type LocationType = QueryResult<typeof LOCATION_QUERY>['location'] & {displayCapacity: string};
type LocationCoordinatesType = NewcomerOverviewComponent['location'];

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss', '../DetailPageHeader.scss']
})
export class LocationComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('newcomerOverviewComponent', {static: false}) newcomerOverviewComponent: NewcomerOverviewComponent | null = null;
  subscriptions = new Subscription();

  location: LocationType | null = null;
  location$: Observable<LocationType>;

  locationCoordinates$ = new BehaviorSubject<LocationCoordinatesType | null>(null);
  newcomersFilters$ = new Subject<{}>();

  mapCenter: google.maps.LatLngLiteral = {lat: 52.0705, lng: 4.3007};
  locationMarker: google.maps.LatLngLiteral | null = null;
  newcomerMarkers: {id: string, name: string, position: google.maps.LatLngLiteral}[] = [];

  newcomerMarkerIcon: google.maps.Icon = {
    url: '/assets/child-solid.svg',
    scaledSize: new google.maps.Size(30, 30),
    labelOrigin: new google.maps.Point(20, 40)
  };
  mapZoom: number = 10;

  mapOptions: google.maps.MapOptions = {
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road.local',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
    ],
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  };

  destroyRef = inject(DestroyRef)

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService
  ) {
    this.location$ = this.activatedRoute.params
      .pipe(
        switchMap(params => {
          return this.apollo
            .watchQuery({query: LOCATION_QUERY, variables: {id: params['locatieId']}}).valueChanges
            .pipe(catchError(() => this.errorService.HandleGraphQLError('Ophalen van locatie')));
        }),
        map(queryResult => {
          const loc = queryResult.data.location;
          return {
            ...loc,
            displayCapacity: loc ? `${loc.remainingCapacity}/${loc.totalCapacity}` : '',
          } as LocationType;
        }),
        share()
      );
  }

  ngOnInit(): void {
    const routeLocationSubscription = this.location$.subscribe(location => {
        this.location = location;

        if (notNullOrUndefined(location)) {
          if (hasNoEmptyAttributes(location, ['latitude', 'longitude'])) {
            this.locationCoordinates$.next({latitude: location.latitude, longitude: location.longitude});
            this.mapCenter = {lat: location.latitude, lng: location.longitude};
            this.locationMarker = {lat: location.latitude, lng: location.longitude};
          }
        }
      });

    this.subscriptions.add(routeLocationSubscription);
  }

  ngAfterViewInit() {
    this.location$.pipe(
      delay(0),
      switchMap(() => this.mapZoom$()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(zoom => this.mapZoom = zoom);

    this.newcomersFilters$.pipe(
      delay(0),
      switchMap((filters) => this.getNewcomers$(filters)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(records => {
        this.newcomerMarkers = records.map(record => {
          return {
            id: record.id,
            name: `${record.fullName}`,
            position: {lat: record.latitude, lng: record.longitude} as google.maps.LatLngLiteral
          };
        });
      });
  }

  mapZoom$(): Observable<number> {
    const overviewComponent = this.newcomerOverviewComponent ?? throwExpression('Overviewcomponent missing');

    function getBaseLog(x: number, y: number): number {
      return Math.log(y) / Math.log(x);
    }

    return overviewComponent.searchForm.controls.rangeFilter.controls.metersRange.valueChanges
      .pipe(
        startWith(overviewComponent.searchForm.value.rangeFilter?.metersRange ?? 3000),
        filter(notNullOrUndefined),
        map(metersRange => metersRange * 4),
        map(metersRange => getBaseLog(2, 40000 / (metersRange / 1000 / 2)))
      );
  }

  getNewcomers$(filters: {} | null) {
    if (filters === null) {
      return EMPTY;
    }

    return this.apollo
      .watchQuery<
        LocationComponent_NewcomersQuery,
        LocationComponent_NewcomersQueryVariables
      >({
        query: NEWCOMERS_QUERY,
        variables: {
          ...filters
        },
      })
      .valueChanges.pipe(
        map((result) => result.data.newcomers.edges.map((edge) => edge.node))
      );
  }

  toCapacity() {
    window.open(environment.wegwijzer_url + '/location/po/' + this.location?.id + '/newcomers' , '_blank');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
