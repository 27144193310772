import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {catchError, combineLatest, map, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {models} from 'src/app/shared/models';

import {FormHelperService} from "../../shared/services/form-helper.service";
import {MessageService} from "primeng/api";
import {Apollo} from "apollo-angular";
import {FormSaveHelper} from "@vasio-nl/valow";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ErrorService} from "../../shared/services/error.service";

@Component({
  selector: 'app-new-placement-offer2',
  templateUrl: './new-placement-offer.component.html',
  styleUrls: ['./new-placement-offer.component.scss']
})
export class NewPlacementOfferComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  public formContainer = this.formHelperService.getFormContainer(models.PlacementOffer, {
    id: true,
    location: {
      id: true,
      name: true,
      capacity: {
        id: true,
        capacity: true,
        remainingCapacity: true,
        age: true,
        description: true
      }
    },
    newcomer: {
      id: true,
      fullName: true
    },
    capacity: {
      id: true,
      capacity: true,
      remainingCapacity: true,
      age: true,
      description: true
    }
  });
  public form = this.formContainer.form;
  public saveHelper = new FormSaveHelper(this.formContainer, this.messageService);

  constructor(private formHelperService: FormHelperService, private errorService: ErrorService, private route: ActivatedRoute, private messageService: MessageService, private apollo: Apollo) {
    // 1. Should it be easier to load related fields into form? - No i don't think so becaues it doesn't occure often and takes a few lines.
    // 2. How to handle required fields?. For now we could just backend it.
    // 3. Cache evicts?
    // 4. Error handling?
  }

  ngOnInit(): void {
    const locationId = this.route.snapshot.queryParams['location_id'];
    const location$ = locationId ? this.formHelperService.getData(models.Location, this.formContainer.fields.location, locationId).pipe(
        map(result => result.location)
    ) : of(null);

    const newcomerId = this.route.snapshot.queryParams['newcomer_id'];
    const newcomer$ = newcomerId ? this.formHelperService.getData(models.NewComer, this.formContainer.fields.newcomer, newcomerId).pipe(
        map(result => result.newcomer)
    ) : of(null);

    this.form.disable();

    combineLatest([location$, newcomer$]).pipe(
      catchError(err => this.errorService.HandleGraphQLError('ophalen van plaatsingsverzoek')),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([location, newcomer]) => {
      this.formHelperService.updateFormFromModel(this.form, {
        location,
        newcomer
      });


      this.form.enable();
    });



    this.saveHelper.onSave$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(result => {
      if(result.errors.length > 0) {
        return;
      }

      const cache = this.apollo.client.cache;
      cache.evict({
        id: `NewComer:${result.placementOffer!.newcomer.id}`,
        fieldName: 'placementOffers'
      });
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'placementOffers'
      });
      cache.evict({
        id: `WegwijzerLocationType:${result.placementOffer!.location!.id}`,
        fieldName: 'capacity'
      });

      this.form.reset();
    });
  }
}
