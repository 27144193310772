<div class="card overview-card">
  <div class="controls">
    <div class="control filling">
      <span class="p-input-icon-left w-100">
        <i class="pi pi-search" *ngIf="!loadingRecordCount"></i>
        <i class="pi pi-spin pi-spinner" *ngIf="loadingRecordCount"></i>
        <input class="p-inputtext w-100" type="text" pInputText [formControl]="searchForm.controls.search"
          placeholder="Zoeken">
      </span>
    </div>
    <div class="control multiselect-control">
      <p-multiSelect [options]="placementOptionsArray" [formControl]="searchForm.controls.placementStatus"
        optionLabel="name" optionValue="value" styleClass="w-100" placeholder="Selecteer status"></p-multiSelect>
    </div>

    <div class="control range-control" *ngIf="rangeFilterActive">
      <p-dropdown [options]="distanceOptionsArray" [formControl]="searchForm.controls.rangeFilter.controls.metersRange"
        optionLabel="name" optionValue="value" styleClass="w-100"></p-dropdown>
    </div>

    <div class="control">
      <p-dropdown [options]="ukrainianOptionsArray" [formControl]="searchForm.controls.speaksUkrainian"
        placeholder="Spreekt Oekraïens" optionLabel="name" optionValue="value" styleClass="w-100"></p-dropdown>
    </div>

    <div class="control">
      <p-dropdown [options]="areas" [showClear]="true" [formControl]="searchForm.controls.areaId" [filter]="true"
        placeholder="Locatie" optionLabel="name" optionValue="id" styleClass="w-100">
        <ng-template let-area pTemplate="item">
          <div class="country-item">
            <div>{{area.name}}</div>
            <div style="font-size: 13px; color: #333;">{{categoryToDisplay(area.category)}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>

    <div class="p-element record-count">({{recordCount}})</div>
  </div>

  <p class="error" *ngIf="showShouldHaveBeenPlacedWarning && !searchForm.controls.shouldHaveBeenPlaced.value"
    (click)="searchForm.controls.shouldHaveBeenPlaced.setValue(true)">Er zijn een of meerdere nieuwkomers die na 3
    maanden nog niet zijn geplaatst. Klik op deze melding om ze te bekijken.</p>

  <app-vtable #table [formHelperService]="formHelperService" [modelInfo]="models.NewComer" [rowHeight]="72"
    [fields]="tableFields" [filters]="filters | async" (onRowClick)="navigate($event)" [additionalFields]="additionalFields"
    (loadingRecordCount)="loadingRecordCount = $event" (recordCount)="recordCount = $event">
  </app-vtable>
</div>
