export default {
    "scalars": [
        1,
        2,
        3,
        5,
        7,
        9,
        10,
        12,
        13,
        14,
        16,
        27,
        28,
        29,
        39
    ],
    "types": {
        "Area": {
            "category": [
                3
            ],
            "id": [
                1
            ],
            "name": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "ID": {},
        "String": {},
        "AreaCategory": {},
        "AuthUserType": {
            "id": [
                1
            ],
            "managerHasAccessToSensitiveMessages": [
                5
            ],
            "name": [
                2
            ],
            "role": [
                39
            ],
            "__typename": [
                2
            ]
        },
        "Boolean": {},
        "Capacity": {
            "age": [
                7
            ],
            "capacity": [
                7
            ],
            "description": [
                2
            ],
            "id": [
                1
            ],
            "isUkraineOnly": [
                5
            ],
            "remainingCapacity": [
                7
            ],
            "reservedCapacity": [
                7
            ],
            "totalCapacity": [
                7
            ],
            "__typename": [
                2
            ]
        },
        "Int": {},
        "CombinedArea": {
            "buurt": [
                0
            ],
            "stadsdeel": [
                0
            ],
            "wijk": [
                0
            ],
            "__typename": [
                2
            ]
        },
        "Date": {},
        "DisabilitySupport": {},
        "ErrorType": {
            "field": [
                2
            ],
            "message": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "Gender": {},
        "JSON": {},
        "Language": {},
        "LocationDistance": {
            "distance": [
                16
            ],
            "location": [
                45
            ],
            "__typename": [
                2
            ]
        },
        "Float": {},
        "LocationType": {
            "on_StringLocationType": [
                40
            ],
            "on_WegwijzerLocationType": [
                45
            ],
            "on_Node": [
                22
            ],
            "__typename": [
                2
            ]
        },
        "Mutation": {
            "createNote": [
                46,
                {
                    "input": [
                        24,
                        "NoteInput!"
                    ]
                }
            ],
            "createOrUpdatePlacementOffer": [
                43,
                {
                    "input": [
                        48,
                        "createOrUpdatePlacementOfferInput!"
                    ]
                }
            ],
            "createOrUpdateUser": [
                50,
                {
                    "input": [
                        49,
                        "createOrUpdateUserInput!"
                    ]
                }
            ],
            "deleteNote": [
                23,
                {
                    "id": [
                        7,
                        "Int!"
                    ]
                }
            ],
            "resetOtp": [
                5,
                {
                    "userId": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "updateNewcomer": [
                41,
                {
                    "input": [
                        21,
                        "NewComerUpdateInput!"
                    ]
                }
            ],
            "updateNote": [
                46,
                {
                    "id": [
                        7,
                        "Int!"
                    ],
                    "text": [
                        2,
                        "String!"
                    ]
                }
            ],
            "updateOrCreateObject": [
                47,
                {
                    "input": [
                        42,
                        "UpdateOrCreateObjectInput!"
                    ]
                }
            ],
            "__typename": [
                2
            ]
        },
        "NewComer": {
            "CDW": [
                2
            ],
            "LBAArrivalDate": [
                9
            ],
            "LBACurrentResidence": [
                2
            ],
            "LBASchool": [
                2
            ],
            "LBASchoolPhone": [
                2
            ],
            "LBASchoolTownship": [
                2
            ],
            "age": [
                7
            ],
            "alreadyInNetherlands": [
                5
            ],
            "arrivalDate": [
                9
            ],
            "centralSignupRemarks": [
                2
            ],
            "city": [
                2
            ],
            "cityArea": [
                2
            ],
            "contactEmail": [
                2
            ],
            "contactFirstName": [
                2
            ],
            "contactLastName": [
                2
            ],
            "contactPhone": [
                2
            ],
            "countryOfOrigin": [
                2
            ],
            "dateOfBirth": [
                9
            ],
            "disabilitySupport": [
                10
            ],
            "disabilitySupportExplanationNote": [
                2
            ],
            "firstName": [
                2
            ],
            "fullName": [
                2
            ],
            "gender": [
                12
            ],
            "hasInterpreter": [
                5
            ],
            "hasSibling": [
                5
            ],
            "houseNumber": [
                2
            ],
            "id": [
                1
            ],
            "isUkrainian": [
                5
            ],
            "languages": [
                14
            ],
            "lastName": [
                2
            ],
            "latitude": [
                16
            ],
            "longitude": [
                16
            ],
            "nearbyLocations": [
                15,
                {
                    "radius": [
                        16,
                        "Float!"
                    ]
                }
            ],
            "neighbourhood": [
                2
            ],
            "neighbourhoodNumber": [
                7
            ],
            "newCity": [
                2
            ],
            "newHouseNumber": [
                2
            ],
            "newStreet": [
                2
            ],
            "newZip": [
                2
            ],
            "notes": [
                23
            ],
            "otherLanguages": [
                2
            ],
            "parentEmail": [
                2
            ],
            "parentFirstName": [
                2
            ],
            "parentLastName": [
                2
            ],
            "parentPhone": [
                2
            ],
            "placementOffers": [
                26
            ],
            "placementStatus": [
                28
            ],
            "poGroup": [
                7
            ],
            "poVo": [
                29
            ],
            "registrationDate": [
                9
            ],
            "remarks": [
                2
            ],
            "schoolPlacement": [
                45
            ],
            "statusHistory": [
                20
            ],
            "street": [
                2
            ],
            "timeline": [
                51,
                {
                    "invertOrder": [
                        5
                    ]
                }
            ],
            "willMoveSoon": [
                5
            ],
            "zip": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "NewComerStatusHistory": {
            "createdAt": [
                9
            ],
            "id": [
                1
            ],
            "newComer": [
                19
            ],
            "oldStatus": [
                28
            ],
            "status": [
                28
            ],
            "__typename": [
                2
            ]
        },
        "NewComerUpdateInput": {
            "CDW": [
                2
            ],
            "LBAArrivalDate": [
                9
            ],
            "LBACurrentResidence": [
                2
            ],
            "LBASchool": [
                2
            ],
            "LBASchoolPhone": [
                2
            ],
            "LBASchoolTownship": [
                2
            ],
            "alreadyInNetherlands": [
                5
            ],
            "arrivalDate": [
                9
            ],
            "centralSignupRemarks": [
                2
            ],
            "city": [
                2
            ],
            "cityArea": [
                2
            ],
            "contactEmail": [
                2
            ],
            "contactFirstName": [
                2
            ],
            "contactLastName": [
                2
            ],
            "contactPhone": [
                2
            ],
            "countryOfOrigin": [
                2
            ],
            "dateOfBirth": [
                9
            ],
            "disabilitySupport": [
                10
            ],
            "disabilitySupportExplanationNote": [
                2
            ],
            "firstName": [
                2
            ],
            "gender": [
                12
            ],
            "hasInterpreter": [
                5
            ],
            "hasSibling": [
                5
            ],
            "houseNumber": [
                2
            ],
            "id": [
                1
            ],
            "isUkrainian": [
                5
            ],
            "languages": [
                14
            ],
            "lastName": [
                2
            ],
            "neighbourhood": [
                2
            ],
            "neighbourhoodNumber": [
                7
            ],
            "newCity": [
                2
            ],
            "newHouseNumber": [
                2
            ],
            "newStreet": [
                2
            ],
            "newZip": [
                2
            ],
            "otherLanguages": [
                2
            ],
            "parentEmail": [
                2
            ],
            "parentFirstName": [
                2
            ],
            "parentLastName": [
                2
            ],
            "parentPhone": [
                2
            ],
            "placementStatus": [
                28
            ],
            "poGroup": [
                7
            ],
            "poVo": [
                29
            ],
            "registrationDate": [
                9
            ],
            "remarks": [
                2
            ],
            "schoolPlacement": [
                2
            ],
            "street": [
                2
            ],
            "willMoveSoon": [
                5
            ],
            "zip": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "Node": {
            "id": [
                1
            ],
            "on_NewComer": [
                19
            ],
            "on_WegwijzerLocationType": [
                45
            ],
            "__typename": [
                2
            ]
        },
        "Note": {
            "createdAt": [
                9
            ],
            "createdBy": [
                44
            ],
            "id": [
                1
            ],
            "newcomer": [
                19
            ],
            "text": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "NoteInput": {
            "newcomer": [
                1
            ],
            "text": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "PageInfo": {
            "endCursor": [
                2
            ],
            "hasNextPage": [
                5
            ],
            "hasPreviousPage": [
                5
            ],
            "startCursor": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "PlacementOffer": {
            "cancelledAt": [
                9
            ],
            "cancelledBy": [
                7
            ],
            "capacity": [
                6
            ],
            "createdAt": [
                9
            ],
            "date": [
                9
            ],
            "failureReason": [
                2
            ],
            "finalized": [
                5
            ],
            "finalizedAt": [
                9
            ],
            "finalizedBy": [
                7
            ],
            "id": [
                1
            ],
            "location": [
                45
            ],
            "locationName": [
                2
            ],
            "needsFinalized": [
                5
            ],
            "newcomer": [
                19
            ],
            "newcomerName": [
                2
            ],
            "resolution": [
                27
            ],
            "resolutionAt": [
                9
            ],
            "resolutionBy": [
                7
            ],
            "school": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "PlacementOfferResolution": {},
        "PlacementStatus": {},
        "PoVo": {},
        "Query": {
            "areas": [
                0
            ],
            "location": [
                45,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "locationArea": [
                8,
                {
                    "latitude": [
                        16,
                        "Float!"
                    ],
                    "longitude": [
                        16,
                        "Float!"
                    ]
                }
            ],
            "locations": [
                31,
                {
                    "after": [
                        2
                    ],
                    "before": [
                        2
                    ],
                    "first": [
                        7
                    ],
                    "isNewcomer": [
                        5
                    ],
                    "last": [
                        7
                    ],
                    "orderBy": [
                        2
                    ],
                    "search": [
                        2
                    ]
                }
            ],
            "me": [
                4
            ],
            "newcomer": [
                19,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "newcomers": [
                33,
                {
                    "after": [
                        2
                    ],
                    "areaId": [
                        1
                    ],
                    "before": [
                        2
                    ],
                    "category": [
                        29
                    ],
                    "first": [
                        7
                    ],
                    "last": [
                        7
                    ],
                    "oldFirst": [
                        5
                    ],
                    "placementStatus__in": [
                        28,
                        "[PlacementStatus!]"
                    ],
                    "placementStatus__notIn": [
                        28,
                        "[PlacementStatus!]"
                    ],
                    "rangeFilter": [
                        37
                    ],
                    "search": [
                        2
                    ],
                    "shouldHaveBeenPlaced": [
                        5
                    ],
                    "speaksUkrainian": [
                        5
                    ]
                }
            ],
            "node": [
                22,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "nodes": [
                22,
                {
                    "ids": [
                        1,
                        "[ID!]!"
                    ]
                }
            ],
            "note": [
                23,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "placementOffer": [
                26,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "placementOffers": [
                35,
                {
                    "after": [
                        2
                    ],
                    "before": [
                        2
                    ],
                    "first": [
                        7
                    ],
                    "last": [
                        7
                    ],
                    "locationId": [
                        1
                    ],
                    "orderBy": [
                        2
                    ],
                    "search": [
                        2
                    ]
                }
            ],
            "report": [
                38,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "reports": [
                38
            ],
            "user": [
                44,
                {
                    "id": [
                        1,
                        "ID!"
                    ]
                }
            ],
            "users": [
                44,
                {
                    "newcomerUser": [
                        5
                    ],
                    "search": [
                        2
                    ]
                }
            ],
            "__typename": [
                2
            ]
        },
        "QueryLocationsConnection": {
            "edges": [
                32
            ],
            "pageInfo": [
                25
            ],
            "totalCount": [
                7
            ],
            "__typename": [
                2
            ]
        },
        "QueryLocationsConnectionEdge": {
            "cursor": [
                2
            ],
            "node": [
                45
            ],
            "__typename": [
                2
            ]
        },
        "QueryNewcomersConnection": {
            "edges": [
                34
            ],
            "pageInfo": [
                25
            ],
            "totalCount": [
                7
            ],
            "__typename": [
                2
            ]
        },
        "QueryNewcomersConnectionEdge": {
            "cursor": [
                2
            ],
            "node": [
                19
            ],
            "__typename": [
                2
            ]
        },
        "QueryPlacementOffersConnection": {
            "edges": [
                36
            ],
            "pageInfo": [
                25
            ],
            "totalCount": [
                7
            ],
            "__typename": [
                2
            ]
        },
        "QueryPlacementOffersConnectionEdge": {
            "cursor": [
                2
            ],
            "node": [
                26
            ],
            "__typename": [
                2
            ]
        },
        "RangeFilter": {
            "latitude": [
                16
            ],
            "longitude": [
                16
            ],
            "metersRange": [
                7
            ],
            "__typename": [
                2
            ]
        },
        "Report": {
            "downloadAsBase64": [
                2
            ],
            "id": [
                1
            ],
            "name": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "Role": {},
        "StringLocationType": {
            "name": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "UpdateNewcomerOutput": {
            "errors": [
                11
            ],
            "newcomer": [
                19
            ],
            "__typename": [
                2
            ]
        },
        "UpdateOrCreateObjectInput": {
            "id": [
                1
            ],
            "model": [
                2
            ],
            "values": [
                13
            ],
            "__typename": [
                2
            ]
        },
        "UpdatePlacementOfferOutput": {
            "errors": [
                11
            ],
            "placementOffer": [
                26
            ],
            "__typename": [
                2
            ]
        },
        "UserType": {
            "email": [
                2
            ],
            "firstName": [
                2
            ],
            "id": [
                1
            ],
            "lastName": [
                2
            ],
            "name": [
                2
            ],
            "newcomerLocations": [
                45
            ],
            "otpActive": [
                5
            ],
            "role": [
                39
            ],
            "__typename": [
                2
            ]
        },
        "WegwijzerLocationType": {
            "capacity": [
                6
            ],
            "capacityDisplay": [
                2
            ],
            "capacityUpdatedAt": [
                9
            ],
            "cityAreaName": [
                2
            ],
            "houseNumber": [
                7
            ],
            "houseNumberAddition": [
                2
            ],
            "id": [
                1
            ],
            "lastReactionOnPlacementOffer": [
                9
            ],
            "latitude": [
                16
            ],
            "longitude": [
                16
            ],
            "name": [
                2
            ],
            "newcomerToken": [
                2
            ],
            "oldestPlacementOffer": [
                9
            ],
            "place": [
                2
            ],
            "remainingCapacity": [
                7
            ],
            "reservedCapacity": [
                7
            ],
            "street": [
                2
            ],
            "totalCapacity": [
                7
            ],
            "zipcode": [
                2
            ],
            "__typename": [
                2
            ]
        },
        "createOrUpdateNoteOutput": {
            "errors": [
                11
            ],
            "note": [
                23
            ],
            "__typename": [
                2
            ]
        },
        "createOrUpdateObjectOutput": {
            "errors": [
                11
            ],
            "object": [
                13
            ],
            "__typename": [
                2
            ]
        },
        "createOrUpdatePlacementOfferInput": {
            "capacity": [
                1
            ],
            "date": [
                9
            ],
            "failureReason": [
                2
            ],
            "finalized": [
                5
            ],
            "finalizedAt": [
                9
            ],
            "finalizedBy": [
                1
            ],
            "id": [
                1
            ],
            "location": [
                1
            ],
            "newcomer": [
                1
            ],
            "newcomerStatus": [
                28
            ],
            "resolution": [
                27
            ],
            "resolutionAt": [
                9
            ],
            "resolutionBy": [
                1
            ],
            "__typename": [
                2
            ]
        },
        "createOrUpdateUserInput": {
            "email": [
                2
            ],
            "firstName": [
                2
            ],
            "id": [
                1
            ],
            "lastName": [
                2
            ],
            "newcomerLocations": [
                1
            ],
            "__typename": [
                2
            ]
        },
        "createOrUpdateUserOutput": {
            "errors": [
                11
            ],
            "user": [
                44
            ],
            "__typename": [
                2
            ]
        },
        "timelineItem": {
            "on_NewComerStatusHistory": [
                20
            ],
            "on_Note": [
                23
            ],
            "on_PlacementOffer": [
                26
            ],
            "__typename": [
                2
            ]
        }
    }
}