import { Component, input } from '@angular/core';
import { FieldInputType } from '@vasio-nl/valow';

@Component({
  selector: 'app-newcomer-age-column',
  standalone: true,
  imports: [],
  templateUrl: './newcomer-age-column.component.html',
  styleUrl: './newcomer-age-column.component.scss'
})
export class NewcomerAgeColumnComponent {
  value = input<string>();
  rowData = input<any>();
  field = input.required<FieldInputType>();
}
