import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {models} from "../../../shared/models";
import {Apollo} from "apollo-angular";
import {catchError} from "rxjs";

import {notNull} from "../../../shared/utils";
import {ActivatedRoute} from "@angular/router";
import {FormHelperService} from "../../../shared/services/form-helper.service";
import {MessageService} from "primeng/api";
import {ErrorService} from "../../../shared/services/error.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {FormSaveHelper} from "@vasio-nl/valow";


@Component({
  selector: 'app-township-newcomer-information',
  templateUrl: './township-newcomer-information.component.html',
  styleUrls: ['./township-newcomer-information.component.scss']
})
export class TownshipNewcomerInformationComponent implements OnInit {

  public formContainer = this.formHelperService.getFormContainer(models.NewComer, {
    id: true,
    CDW: true,
    LBAArrivalDate: true,
    LBACurrentResidence: true,
    LBASchool: true,
    LBASchoolPhone: true,
    LBASchoolTownship: true,
  });
  public form = this.formContainer.form;

  public saveHelper = new FormSaveHelper(this.formContainer, this.messageService);
  destroyRef = inject(DestroyRef);

  constructor(private formHelperService: FormHelperService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private errorService: ErrorService) {
  }

  ngOnInit(): void {
    // loadingSignal, data$ as output
    // arguments: afterResponse, afterPrepare, handleErrors.

    this.formHelperService.getData(this.formContainer.model, this.formContainer.fields, this.route.snapshot.params['newcomerId']).pipe(
      catchError(err => this.errorService.HandleGraphQLError('Laden van nieuwkomer gegevens')),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(data => {
      const preparedData = this.formHelperService.prepareDataForForm(this.formContainer.model, this.formContainer.fields, notNull(data.newcomer));
      this.formHelperService.updateFormFromModel(this.form, preparedData);
    });

    // savingSignal, saveFunction, response$
    // arguments: afterValidation, afterPrepare, handleTransimissionErrors, handleServerErrors, showSuccessMessage

    this.saveHelper.onSave$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
