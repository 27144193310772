import { Component, input } from '@angular/core';
import { FieldInputType } from '@vasio-nl/valow';

@Component({
  selector: 'app-newcomer-name-column',
  templateUrl: './newcomer-name-column.component.html',
  styleUrl: './newcomer-name-column.component.scss'
})
export class NewcomerNameColumnComponent {
  value = input<string>();
  rowData = input<any>();
  field = input.required<FieldInputType>();
}
