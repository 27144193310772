import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CallbackComponent } from './pages/callback/callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { environment } from '../environments/environment';
import { NewcomerOverviewComponent } from './pages/newcomer-overview/newcomer-overview.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { NewcomerComponent } from './pages/newcomer/newcomer.component';
import { LoadingBarComponent } from './common/loading-bar/loading-bar.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { SkeletonModule } from 'primeng/skeleton';
import { TabViewModule } from 'primeng/tabview';
import { GeneralNewcomerInformationComponent } from './pages/newcomer/general-newcomer-information/general-newcomer-information.component';
import { SpinnerOverlayComponent } from './common/spinner-overlay/spinner-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastModule } from 'primeng/toast';
import { FormFooterComponent } from './common/form-footer/form-footer.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FormFieldErrorsComponent } from './common/form-field-errors/form-field-errors.component';
import { LocationOverviewComponent } from './pages/location-overview/location-overview.component';
import { LocationComponent } from './pages/location/location.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TownshipNewcomerInformationComponent } from './pages/newcomer/township-newcomer-information/township-newcomer-information.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { NewcomerPlacementInformationComponent } from './pages/newcomer/newcomer-placement-information/newcomer-placement-information.component';
import { default as generatedIntrospection } from '../gql/possibleTypes';
import { SlideMenuModule } from 'primeng/slidemenu';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { ErrorService } from './shared/services/error.service';
import { Router, RouteReuseStrategy } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomRouteReuseStrategy } from './app-route-reuse-strategy';
import { NewcomerExportComponent } from './pages/newcomer/newcomer-export/newcomer-export.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { PlacementOfferOverviewComponent } from './pages/placement-offer-overview/placement-offer-overview.component';
import { PlacementOfferComponent } from './pages/placement-offer/placement-offer.component';
import { SharedModule } from 'primeng/api';
import { ToastrModule } from 'ngx-toastr';
import { UsersOverviewComponent } from './pages/users-overview/users-overview.component';
import { NewcomerTimelineComponent } from './pages/newcomer/newcomer-timeline/newcomer-timeline.component';
import { LineBreaksPipe } from './shared/pipes/line-breaks.pipe';
import { NearbyLocationsComponent } from './pages/newcomer/nearby-locations/nearby-locations.component';
import { NewPlacementOfferComponent } from './pages/new-placement-offer/new-placement-offer.component';
import { FormHelperService } from './shared/services/form-helper.service';
import { UserComponent } from './pages/user/user.component';
import { DatePipe } from '@angular/common';
import { APP_FORM_HELPER_SERVICE, ValowModule } from '@vasio-nl/valow';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { PlacementOfferResolutionColumnComponent } from './pages/placement-offer-overview/placement-offer-solution-column/placement-offer-resolution-column.component';
import { NewcomerStatusColumnComponent } from './pages/newcomer-overview/newcomer-status-column/newcomer-status-column.component';
import { NewcomerNameColumnComponent } from './pages/newcomer-overview/newcomer-name-column/newcomer-name-column.component';
import { HttpConfigInterceptor } from './http.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    NewcomerOverviewComponent,
    NewcomerComponent,
    LoadingBarComponent,
    GeneralNewcomerInformationComponent,
    SpinnerOverlayComponent,
    FormFooterComponent,
    ErrorDialogComponent,
    FormFieldErrorsComponent,
    LocationOverviewComponent,
    LocationComponent,
    TownshipNewcomerInformationComponent,
    NewcomerPlacementInformationComponent,
    NoAccessComponent,
    NewcomerExportComponent,
    UsersOverviewComponent,
    ReportsComponent,
    PlacementOfferOverviewComponent,
    PlacementOfferComponent,
    NewPlacementOfferComponent,
    NewcomerTimelineComponent,
    LineBreaksPipe,
    NearbyLocationsComponent,
    UserComponent,
    PlacementOfferResolutionColumnComponent,
    NewcomerStatusColumnComponent,
    NewcomerNameColumnComponent
  ],
  bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        OAuthModule.forRoot(),
        ApolloModule,
        TableModule,
        DropdownModule,
        DynamicDialogModule,
        ButtonModule,
        RippleModule,
        TagModule,
        ProgressBarModule,
        SkeletonModule,
        TabViewModule,
        OverlayModule,
        ToastModule,
        GoogleMapsModule,
        TabMenuModule,
        MultiSelectModule,
        SharedModule,
        TableModule,
        ToastrModule.forRoot(),
        SlideMenuModule,
        ValowModule,
        InputTextareaModule,
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, errorService: ErrorService) {
        const link = httpLink.create({ uri: environment.graphql_endpoint });
        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (networkError) {
            errorService.displayError(
              'Er kan niet met de server gecommuniceerd worden.',
              'Controleer uw verbinding en probeer het opnieuw.'
            );
          }
          if (graphQLErrors) {
            if (!environment.production) {
              console.error(graphQLErrors);
            }
            Sentry.captureException(new Error('GraphQL error'), (scope) => {
              scope.setContext('errors', {
                errors: JSON.stringify(graphQLErrors),
              });
              scope.setLevel('error');
              return scope;
            });
          }
        });
        return {
          cache: new InMemoryCache({
            possibleTypes: generatedIntrospection.possibleTypes,
          }),
          link: from([errorLink, link]),
        } as ApolloClientOptions<any>;
      },
      deps: [HttpLink, ErrorService],
    },
    FormHelperService,
    {
      provide: APP_FORM_HELPER_SERVICE,
      useClass: FormHelperService,
    },
    provideHttpClient(withInterceptorsFromDi()),
    DatePipe,
  ],
})
export class AppModule {}
