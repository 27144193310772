import { Component, input } from '@angular/core';
import { PLACEMENT_STATUS_OPTIONS } from '../newcomer-overview.component';
import { FieldInputType } from '@vasio-nl/valow';
import { PlacementStatus } from 'src/gql/graphql';

@Component({
  selector: 'app-newcomer-status-column',
  templateUrl: './newcomer-status-column.component.html',
  styleUrl: './newcomer-status-column.component.scss'
})
export class NewcomerStatusColumnComponent {
  value = input<PlacementStatus>();
  field = input.required<FieldInputType>();
  rowData = input<any>();

  placementOptions = PLACEMENT_STATUS_OPTIONS;
}
