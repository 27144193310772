import {Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {gql} from '../../../../gql';
import {
  FormModelType,
  hasNoEmptyAttributes,
  ModelToForm, notNull,
  QueryResult,
  QueryVariables, TableColumn, throwExpression,
  triggerFormGroupValidation
} from '../../../shared/utils';
import {FormControl, FormGroup} from '@angular/forms';
import {Apollo} from 'apollo-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../../../shared/services/error.service';
import {catchError, exhaustMap, filter, map, Subject, Subscription, switchMap} from 'rxjs';
import {PlacementOfferResolution, PlacementStatus, PoVo} from '../../../../gql/graphql';
import {FormFooterComponent} from '../../../common/form-footer/form-footer.component';
import {Table} from 'primeng/table';
import { models } from 'src/app/shared/models';
import {FormHelperService} from "../../../shared/services/form-helper.service";
import {FormSaveHelper} from '@vasio-nl/valow';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {PLACEMENT_OFFER_OPTIONS} from '../../placement-offer/placement-offer.component';

gql(/* GraphQL */`
  fragment NewcomerPlacementInformationComponent_newcomerFields on NewComer {
    id
    centralSignupRemarks
    placementStatus
    placementOffers {
      id
      date
      resolution
      resolutionAt
      finalized
      location {
        ...on WegwijzerLocationType {
          id
          name
        }
      }
    }
    schoolPlacement {
      __typename
      ... on WegwijzerLocationType {
        id
        name
      }
    }
    poVo
  }
`);

const NEWCOMER_QUERY = gql(/* GraphQL */`
  query NewcomerPlacementInformationComponent_newcomer($id: ID!) {
    newcomer(id: $id) {
      ...NewcomerPlacementInformationComponent_newcomerFields
    }
  }
`);

type QueryRecordType = NonNullable<QueryResult<typeof NEWCOMER_QUERY>['newcomer']>['placementOffers'][0];
type RecordType = Pick<QueryRecordType, 'id' | 'resolution' | 'date' | 'resolutionAt'> & { location: string, needsFinalized: boolean };

const COLUMNS: (TableColumn<RecordType>)[] = [
  {
    field: 'date',
    key: 'date',
    name: 'Voorgesteld',
    width: '150px',
  },
  {
    field: 'resolution',
    key: 'resolution',
    name: 'status',
    width: '180px',
  },
  {
    field: 'resolutionAt',
    key: 'resolutionAt',
    name: 'Besluit',
    width: '150px',
  },
  {
    field: 'location',
    key: 'location',
    name: 'Locatie',
    width: '',
  }
];


@Component({
  selector: 'app-newcomer-placement-information2',
  templateUrl: './newcomer-placement-information.component.html',
  styleUrls: ['./newcomer-placement-information.component.scss']
})
export class NewcomerPlacementInformationComponent implements OnInit, OnDestroy {
  @ViewChild('footerComponent', {static: true}) footerComponent: FormFooterComponent | null = null;
  @ViewChild('table', {static: true}) table: Table | null = null;
  destroyRef = inject(DestroyRef);

  public formContainer = this.formHelperService.getFormContainer(models.NewComer, {
    id: true,
    centralSignupRemarks: true,
    placementStatus: true,
    schoolPlacement: true
  });
  public form = this.formContainer.form;
  public saveHelper = new FormSaveHelper(this.formContainer, this.messageService);

  columns = COLUMNS;
  placementOfferOptions = PLACEMENT_OFFER_OPTIONS;

  category: PoVo | null = null;

  subscriptions = new Subscription();



  constructor(
    private apollo: Apollo,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private messageService: MessageService,
    private router: Router,
    private formHelperService: FormHelperService
  ) {
  }

  ngOnInit(): void {
    this.initializeLoadFlow();

    this.saveHelper.onSave$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private initializeLoadFlow() {
    const table = this.table ?? throwExpression('Table did not initialize');

    this.formHelperService.getData(models.NewComer, {
      ...this.formContainer.fields,
      placementOffers: {
        id: true,
        date: true,
        resolution: true,
        resolutionAt: true,
        finalized: true,
        location: {
          id: true,
          name: true
        }
      }
    }, this.route.snapshot.params['newcomerId']).subscribe(data => {
      const preparedData = this.formHelperService.prepareDataForForm(this.formContainer.model, this.formContainer.fields, notNull(data.newcomer));
      this.formHelperService.updateFormFromModel(this.form, preparedData);

      const records = data.newcomer?.placementOffers.map(placementOffer => {
        return {
          id: placementOffer.id,
          resolution: placementOffer.resolution,
          date: placementOffer.date,
          resolutionAt: placementOffer.resolutionAt,
          location: placementOffer.location?.name ?? '',
          needsFinalized: placementOffer.resolution !== PlacementOfferResolution.AWAITING_RESOLUTION && !placementOffer.finalized,
        } as RecordType;
      });

      table.value = records ?? [];
    });
  }

  newPlacementOffer() {
    this.router.navigate(['/', 'nieuw-plaatsing-voorstel'], {queryParams: {'newcomer_id': this.form.controls.id.value}});
  }

  assertItemType(item: RecordType): RecordType {
    return item;
  }

  navigate(offer: RecordType): void {
    this.router.navigate(['/', 'plaatsing-voorstel', offer.id]);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
