/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n        query AppComponent_user {\n          me { id name }\n        }\n      ": types.AppComponent_UserDocument,
    "\n  query LocationComponent_information($id: ID!) {\n    location(id: $id) {\n      id\n      name\n      street\n      houseNumber\n      houseNumberAddition\n      zipcode\n      place\n      latitude\n      longitude\n      newcomerToken\n      totalCapacity\n      remainingCapacity\n    }\n  }\n": types.LocationComponent_InformationDocument,
    "\n  query LocationComponent_newcomers(\n      $search: String,\n      $rangeFilter: RangeFilter,\n      $areaId: ID,\n      $placementStatus__in: [PlacementStatus!],\n      $shouldHaveBeenPlaced: Boolean,\n      $speaksUkrainian: Boolean\n  ) {\n      newcomers(\n          search: $search,\n          rangeFilter: $rangeFilter,\n          areaId: $areaId,\n          placementStatus__in: $placementStatus__in,\n          shouldHaveBeenPlaced: $shouldHaveBeenPlaced,\n          speaksUkrainian: $speaksUkrainian,\n          oldFirst: true,\n      ) {\n          edges {\n              node {\n                  __typename\n                  id\n                  fullName\n                  latitude\n                  longitude\n              }\n          }\n      }\n  }\n": types.LocationComponent_NewcomersDocument,
    "\n    query OverviewComponent_shouldHaveBeenPlaced {\n        newcomers(shouldHaveBeenPlaced: true) {\n            totalCount\n        }\n    }\n": types.OverviewComponent_ShouldHaveBeenPlacedDocument,
    "\n    query OverviewComponent_areas {\n        areas {\n            id\n            name\n            category\n        }\n    }\n": types.OverviewComponent_AreasDocument,
    "\n query GeneralNewcomerInformationComponent_rights {\n   me {\n     id\n     managerHasAccessToSensitiveMessages\n    }\n   }\n": types.GeneralNewcomerInformationComponent_RightsDocument,
    "\n  query NearbyLocationsComponent_nearbyLocations($id: ID!) {\n    newcomer(id: $id) {\n      id\n      nearbyLocations(radius: 5000) {\n        distance\n        location {\n          id\n          name\n          cityAreaName\n          remainingCapacity\n          totalCapacity\n        }\n      }\n    }\n  }\n": types.NearbyLocationsComponent_NearbyLocationsDocument,
    "\n  query NewcomerExportComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n      firstName\n      lastName\n      dateOfBirth\n      age\n      parentFirstName\n      parentLastName\n      parentEmail\n      parentPhone\n      contactFirstName\n      contactLastName\n      contactEmail\n      contactPhone\n      street\n      houseNumber\n      zip\n      city\n      languages\n      gender\n    }\n  }\n": types.NewcomerExportComponent_InformationDocument,
    "\n  fragment NewcomerPlacementInformationComponent_newcomerFields on NewComer {\n    id\n    centralSignupRemarks\n    placementStatus\n    placementOffers {\n      id\n      date\n      resolution\n      resolutionAt\n      finalized\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n    schoolPlacement {\n      __typename\n      ... on WegwijzerLocationType {\n        id\n        name\n      }\n    }\n    poVo\n  }\n": types.NewcomerPlacementInformationComponent_NewcomerFieldsFragmentDoc,
    "\n  query NewcomerPlacementInformationComponent_newcomer($id: ID!) {\n    newcomer(id: $id) {\n      ...NewcomerPlacementInformationComponent_newcomerFields\n    }\n  }\n": types.NewcomerPlacementInformationComponent_NewcomerDocument,
    "\n  query NewcomerTimelineComponent_timeline($id: ID!) {\n    newcomer(id: $id) {\n      id\n      timeline {\n        ...NewcomerTimelineComponent_timelineFragment\n      }\n    }\n  }\n\n  fragment NewcomerTimelineComponent_timelineFragment on timelineItem {\n    ...on NewComerStatusHistory {\n      id\n      createdAt\n      oldStatus\n      status\n    }\n    ...on Note {\n      id\n      createdAt\n      text\n    }\n    ...on PlacementOffer {\n      id\n      createdAt\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.NewcomerTimelineComponent_TimelineDocument,
    "\n  query NewcomerTimelineComponent_rights {\n    me {\n      id\n      managerHasAccessToSensitiveMessages\n    }\n  }\n": types.NewcomerTimelineComponent_RightsDocument,
    "\n  query NewcomerComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n\n      firstName\n      lastName\n\n      placementStatus\n      age\n      poVo\n      poGroup\n      registrationDate\n      longitude\n      latitude\n    }\n  }\n": types.NewcomerComponent_InformationDocument,
    "\n  query PlacementOfferOverviewComponent_placementOffer {\n    placementOffers(first: 1000) {\n      edges {\n        node {\n          id\n          date\n          resolution\n          resolutionAt\n          failureReason\n          finalizedAt\n          finalized\n          location {\n            ... on WegwijzerLocationType {\n              id\n              name\n            }\n          }\n          newcomer {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n": types.PlacementOfferOverviewComponent_PlacementOfferDocument,
    "\n  query PlacementOfferComponent_placementOfferQuery($id: ID!) {\n    placementOffer(id: $id) {\n      id\n      newcomer {\n        id\n        firstName\n        lastName\n        placementStatus\n      }\n      location {\n        ... on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n      capacity {\n        id\n        age\n        description\n      }\n      date\n      resolutionAt\n      resolution\n      resolutionBy\n      failureReason\n      finalized\n      finalizedAt\n    }\n  }\n": types.PlacementOfferComponent_PlacementOfferQueryDocument,
    "\n  mutation PlacementOfferComponent_placementOfferMutation($input: createOrUpdatePlacementOfferInput!) {\n    createOrUpdatePlacementOffer(input: $input) {\n      errors {\n        field\n      }\n    }\n  }\n": types.PlacementOfferComponent_PlacementOfferMutationDocument,
    "\n    query PlacementOfferComponent_rights {\n      me {\n        id\n        managerHasAccessToSensitiveMessages\n      }\n    }\n\n": types.PlacementOfferComponent_RightsDocument,
    "\n    query ReportsComponent__get_reports {\n        reports {\n            id\n            name\n            __typename\n        }\n    }\n": types.ReportsComponent__Get_ReportsDocument,
    "\n    query ReportsComponent__get_report($id: ID!) {\n        report(id: $id) {\n            id\n            name\n            downloadAsBase64\n            __typename\n        }\n    }\n": types.ReportsComponent__Get_ReportDocument,
    "\n  mutation UserComponent_reset_otp($userId: ID!) {\n    resetOtp(userId: $userId)\n  }\n": types.UserComponent_Reset_OtpDocument,
    "\n  query UserComponent_search($search: String) {\n    users(search: $search, newcomerUser: false) {\n      id\n      email\n    }\n  }\n": types.UserComponent_SearchDocument,
    "\n  query UsersOverviewComponent_users(\n    $search: String\n  ) {\n    users(\n      search: $search\n    ) {\n      id\n      name\n      email\n      newcomerLocations {\n        id\n        name\n      }\n    }\n  }\n": types.UsersOverviewComponent_UsersDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        query AppComponent_user {\n          me { id name }\n        }\n      "): (typeof documents)["\n        query AppComponent_user {\n          me { id name }\n        }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LocationComponent_information($id: ID!) {\n    location(id: $id) {\n      id\n      name\n      street\n      houseNumber\n      houseNumberAddition\n      zipcode\n      place\n      latitude\n      longitude\n      newcomerToken\n      totalCapacity\n      remainingCapacity\n    }\n  }\n"): (typeof documents)["\n  query LocationComponent_information($id: ID!) {\n    location(id: $id) {\n      id\n      name\n      street\n      houseNumber\n      houseNumberAddition\n      zipcode\n      place\n      latitude\n      longitude\n      newcomerToken\n      totalCapacity\n      remainingCapacity\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LocationComponent_newcomers(\n      $search: String,\n      $rangeFilter: RangeFilter,\n      $areaId: ID,\n      $placementStatus__in: [PlacementStatus!],\n      $shouldHaveBeenPlaced: Boolean,\n      $speaksUkrainian: Boolean\n  ) {\n      newcomers(\n          search: $search,\n          rangeFilter: $rangeFilter,\n          areaId: $areaId,\n          placementStatus__in: $placementStatus__in,\n          shouldHaveBeenPlaced: $shouldHaveBeenPlaced,\n          speaksUkrainian: $speaksUkrainian,\n          oldFirst: true,\n      ) {\n          edges {\n              node {\n                  __typename\n                  id\n                  fullName\n                  latitude\n                  longitude\n              }\n          }\n      }\n  }\n"): (typeof documents)["\n  query LocationComponent_newcomers(\n      $search: String,\n      $rangeFilter: RangeFilter,\n      $areaId: ID,\n      $placementStatus__in: [PlacementStatus!],\n      $shouldHaveBeenPlaced: Boolean,\n      $speaksUkrainian: Boolean\n  ) {\n      newcomers(\n          search: $search,\n          rangeFilter: $rangeFilter,\n          areaId: $areaId,\n          placementStatus__in: $placementStatus__in,\n          shouldHaveBeenPlaced: $shouldHaveBeenPlaced,\n          speaksUkrainian: $speaksUkrainian,\n          oldFirst: true,\n      ) {\n          edges {\n              node {\n                  __typename\n                  id\n                  fullName\n                  latitude\n                  longitude\n              }\n          }\n      }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query OverviewComponent_shouldHaveBeenPlaced {\n        newcomers(shouldHaveBeenPlaced: true) {\n            totalCount\n        }\n    }\n"): (typeof documents)["\n    query OverviewComponent_shouldHaveBeenPlaced {\n        newcomers(shouldHaveBeenPlaced: true) {\n            totalCount\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query OverviewComponent_areas {\n        areas {\n            id\n            name\n            category\n        }\n    }\n"): (typeof documents)["\n    query OverviewComponent_areas {\n        areas {\n            id\n            name\n            category\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query GeneralNewcomerInformationComponent_rights {\n   me {\n     id\n     managerHasAccessToSensitiveMessages\n    }\n   }\n"): (typeof documents)["\n query GeneralNewcomerInformationComponent_rights {\n   me {\n     id\n     managerHasAccessToSensitiveMessages\n    }\n   }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NearbyLocationsComponent_nearbyLocations($id: ID!) {\n    newcomer(id: $id) {\n      id\n      nearbyLocations(radius: 5000) {\n        distance\n        location {\n          id\n          name\n          cityAreaName\n          remainingCapacity\n          totalCapacity\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NearbyLocationsComponent_nearbyLocations($id: ID!) {\n    newcomer(id: $id) {\n      id\n      nearbyLocations(radius: 5000) {\n        distance\n        location {\n          id\n          name\n          cityAreaName\n          remainingCapacity\n          totalCapacity\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewcomerExportComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n      firstName\n      lastName\n      dateOfBirth\n      age\n      parentFirstName\n      parentLastName\n      parentEmail\n      parentPhone\n      contactFirstName\n      contactLastName\n      contactEmail\n      contactPhone\n      street\n      houseNumber\n      zip\n      city\n      languages\n      gender\n    }\n  }\n"): (typeof documents)["\n  query NewcomerExportComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n      firstName\n      lastName\n      dateOfBirth\n      age\n      parentFirstName\n      parentLastName\n      parentEmail\n      parentPhone\n      contactFirstName\n      contactLastName\n      contactEmail\n      contactPhone\n      street\n      houseNumber\n      zip\n      city\n      languages\n      gender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NewcomerPlacementInformationComponent_newcomerFields on NewComer {\n    id\n    centralSignupRemarks\n    placementStatus\n    placementOffers {\n      id\n      date\n      resolution\n      resolutionAt\n      finalized\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n    schoolPlacement {\n      __typename\n      ... on WegwijzerLocationType {\n        id\n        name\n      }\n    }\n    poVo\n  }\n"): (typeof documents)["\n  fragment NewcomerPlacementInformationComponent_newcomerFields on NewComer {\n    id\n    centralSignupRemarks\n    placementStatus\n    placementOffers {\n      id\n      date\n      resolution\n      resolutionAt\n      finalized\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n    schoolPlacement {\n      __typename\n      ... on WegwijzerLocationType {\n        id\n        name\n      }\n    }\n    poVo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewcomerPlacementInformationComponent_newcomer($id: ID!) {\n    newcomer(id: $id) {\n      ...NewcomerPlacementInformationComponent_newcomerFields\n    }\n  }\n"): (typeof documents)["\n  query NewcomerPlacementInformationComponent_newcomer($id: ID!) {\n    newcomer(id: $id) {\n      ...NewcomerPlacementInformationComponent_newcomerFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewcomerTimelineComponent_timeline($id: ID!) {\n    newcomer(id: $id) {\n      id\n      timeline {\n        ...NewcomerTimelineComponent_timelineFragment\n      }\n    }\n  }\n\n  fragment NewcomerTimelineComponent_timelineFragment on timelineItem {\n    ...on NewComerStatusHistory {\n      id\n      createdAt\n      oldStatus\n      status\n    }\n    ...on Note {\n      id\n      createdAt\n      text\n    }\n    ...on PlacementOffer {\n      id\n      createdAt\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewcomerTimelineComponent_timeline($id: ID!) {\n    newcomer(id: $id) {\n      id\n      timeline {\n        ...NewcomerTimelineComponent_timelineFragment\n      }\n    }\n  }\n\n  fragment NewcomerTimelineComponent_timelineFragment on timelineItem {\n    ...on NewComerStatusHistory {\n      id\n      createdAt\n      oldStatus\n      status\n    }\n    ...on Note {\n      id\n      createdAt\n      text\n    }\n    ...on PlacementOffer {\n      id\n      createdAt\n      location {\n        ...on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewcomerTimelineComponent_rights {\n    me {\n      id\n      managerHasAccessToSensitiveMessages\n    }\n  }\n"): (typeof documents)["\n  query NewcomerTimelineComponent_rights {\n    me {\n      id\n      managerHasAccessToSensitiveMessages\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewcomerComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n\n      firstName\n      lastName\n\n      placementStatus\n      age\n      poVo\n      poGroup\n      registrationDate\n      longitude\n      latitude\n    }\n  }\n"): (typeof documents)["\n  query NewcomerComponent_information($id: ID!) {\n    newcomer(id: $id) {\n      id\n\n      firstName\n      lastName\n\n      placementStatus\n      age\n      poVo\n      poGroup\n      registrationDate\n      longitude\n      latitude\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PlacementOfferOverviewComponent_placementOffer {\n    placementOffers(first: 1000) {\n      edges {\n        node {\n          id\n          date\n          resolution\n          resolutionAt\n          failureReason\n          finalizedAt\n          finalized\n          location {\n            ... on WegwijzerLocationType {\n              id\n              name\n            }\n          }\n          newcomer {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PlacementOfferOverviewComponent_placementOffer {\n    placementOffers(first: 1000) {\n      edges {\n        node {\n          id\n          date\n          resolution\n          resolutionAt\n          failureReason\n          finalizedAt\n          finalized\n          location {\n            ... on WegwijzerLocationType {\n              id\n              name\n            }\n          }\n          newcomer {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PlacementOfferComponent_placementOfferQuery($id: ID!) {\n    placementOffer(id: $id) {\n      id\n      newcomer {\n        id\n        firstName\n        lastName\n        placementStatus\n      }\n      location {\n        ... on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n      capacity {\n        id\n        age\n        description\n      }\n      date\n      resolutionAt\n      resolution\n      resolutionBy\n      failureReason\n      finalized\n      finalizedAt\n    }\n  }\n"): (typeof documents)["\n  query PlacementOfferComponent_placementOfferQuery($id: ID!) {\n    placementOffer(id: $id) {\n      id\n      newcomer {\n        id\n        firstName\n        lastName\n        placementStatus\n      }\n      location {\n        ... on WegwijzerLocationType {\n          id\n          name\n        }\n      }\n      capacity {\n        id\n        age\n        description\n      }\n      date\n      resolutionAt\n      resolution\n      resolutionBy\n      failureReason\n      finalized\n      finalizedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PlacementOfferComponent_placementOfferMutation($input: createOrUpdatePlacementOfferInput!) {\n    createOrUpdatePlacementOffer(input: $input) {\n      errors {\n        field\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PlacementOfferComponent_placementOfferMutation($input: createOrUpdatePlacementOfferInput!) {\n    createOrUpdatePlacementOffer(input: $input) {\n      errors {\n        field\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query PlacementOfferComponent_rights {\n      me {\n        id\n        managerHasAccessToSensitiveMessages\n      }\n    }\n\n"): (typeof documents)["\n    query PlacementOfferComponent_rights {\n      me {\n        id\n        managerHasAccessToSensitiveMessages\n      }\n    }\n\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ReportsComponent__get_reports {\n        reports {\n            id\n            name\n            __typename\n        }\n    }\n"): (typeof documents)["\n    query ReportsComponent__get_reports {\n        reports {\n            id\n            name\n            __typename\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ReportsComponent__get_report($id: ID!) {\n        report(id: $id) {\n            id\n            name\n            downloadAsBase64\n            __typename\n        }\n    }\n"): (typeof documents)["\n    query ReportsComponent__get_report($id: ID!) {\n        report(id: $id) {\n            id\n            name\n            downloadAsBase64\n            __typename\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserComponent_reset_otp($userId: ID!) {\n    resetOtp(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation UserComponent_reset_otp($userId: ID!) {\n    resetOtp(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserComponent_search($search: String) {\n    users(search: $search, newcomerUser: false) {\n      id\n      email\n    }\n  }\n"): (typeof documents)["\n  query UserComponent_search($search: String) {\n    users(search: $search, newcomerUser: false) {\n      id\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UsersOverviewComponent_users(\n    $search: String\n  ) {\n    users(\n      search: $search\n    ) {\n      id\n      name\n      email\n      newcomerLocations {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query UsersOverviewComponent_users(\n    $search: String\n  ) {\n    users(\n      search: $search\n    ) {\n      id\n      name\n      email\n      newcomerLocations {\n        id\n        name\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;