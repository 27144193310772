import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './shared/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import {Apollo} from 'apollo-angular';
import {gql} from '../gql';
import {AppComponent_UserQuery} from '../gql/graphql';
import {FilterService, MenuItem, MessageService, PrimeIcons, PrimeNGConfig} from 'primeng/api';
import {ErrorService} from './shared/services/error.service';
import {environment} from '../environments/environment';
import {catchError} from 'rxjs';
import {ValowService, FieldType, CalendarComponent, TextInputComponent, TextAreaComponent, EnumSelectComponent, MultiEnumSelectComponent, NumberInputComponent, BooleanDropdownComponent, DropdownComponent, DropdownMultipleComponent} from '@vasio-nl/valow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'newcomers-angular';
  me: AppComponent_UserQuery['me'] | null = null;

  wegwijzerPath = environment.wegwijzer_url;

  meMenuItems: MenuItem[] = [
    {
      label: 'Uitloggen',
      icon: PrimeIcons.SIGN_OUT,
      command: event => this.logout()
    }
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private apollo: Apollo,
    private primengConfig: PrimeNGConfig,
    private errorService: ErrorService,
    private filterService: FilterService,
    private valow: ValowService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.authenticationService.initializeOAuth();

    this.valow.init({
      language: "nl",
      globalFieldConfiguration: {
        [FieldType.RELATION]: {
          component: DropdownComponent
        },
        [FieldType.DATE]: {
          component: CalendarComponent
        },
        [FieldType.STRING]: {
          component: TextInputComponent
        },
        [FieldType.TEXTAREA]: {
          component: TextAreaComponent
        },
        [FieldType.RELATION_MANY]: {
          component: DropdownMultipleComponent
        },
        [FieldType.ENUM_SELECT]: {
          component: EnumSelectComponent
        },
        [FieldType.ENUM_MULTI_SELECT]: {
          component: MultiEnumSelectComponent
        },
        [FieldType.NUMBER]: {
          component: NumberInputComponent
        },
        [FieldType.BOOLEAN]: {
          component: BooleanDropdownComponent
        },
        [FieldType.FILE_UPLOAD]: {
          component: BooleanDropdownComponent
        },
        [FieldType.IMAGE_UPLOAD]: {
          component: BooleanDropdownComponent
        }
      },

      showMessage: (message) => {
        this.messageService.add(message);
      }
    });

    this.apollo.query({
      query: gql(/* GraphQL */`
        query AppComponent_user {
          me { id name }
        }
      `),
    })
    .pipe(catchError(() => this.errorService.HandleGraphQLError('Ophalen van gebruiker')))
    .subscribe(result => this.me = result.data.me);


    this.filterService.register('none', (value: any, filter: any) => {
      return true;
    });
  }

  private logout() {
    this.httpClient.get(`${environment.api_endpoint}/sign-out`, {headers: {Authorization: `Bearer ${this.authenticationService.getToken()}`}, responseType: 'text'})
      .subscribe({
        next: response => {
          location.href = environment.oauth_endpoint + '/accounts/logout/';
        },
        error: error => console.error('logout failed')
      });
  }
}
